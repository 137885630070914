import { useContext, useEffect, useRef } from 'react';

import AuthContext from '../HO/Auth/AuthContext';

const Gtag = ({ id }) => {
  const { user } = useContext(AuthContext);
  const isConfigsMount = useRef(false);

  useEffect(() => {
    if (id && !isConfigsMount.current) {
      isConfigsMount.current = true;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
        ...(user ? { userId: user.id } : {}),
      });
      const f = document.getElementsByTagName('script')[0];
      const j = document.createElement('script');
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${id} `;
      f.parentNode.insertBefore(j, f);
    }
  }, [id, user]);

  return null;
};

export default Gtag;
