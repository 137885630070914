import React from 'react';

import * as icons from './countries';
import { ReactComponent as empty } from '../../assets/img/languages/empty.svg';

const flags = {
  ...icons,
  emptyIcon: empty,
  // for languages list
  ukIcon: icons.uaIcon,
  enIcon: icons.gbIcon,
};

const Flag = ({ iso, ...other }) => {
  const Img = flags[(`${(iso || 'empty').toLowerCase()}Icon`)] || empty;

  return Img ? (<Img {...other} alt={iso} />) : null;
};

export default Flag;
