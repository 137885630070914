import React, { useContext, useState } from 'react';

import {
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import TranslationsContext from '../../../Container/Language/TranslationsContext';
import AuthContext from '../../../HO/Auth/AuthContext';
import Accordion from './Accordion';
import AccordionSummary from './AccordionSummary';

import SmsIcon from '../../../../assets/img/sidebar/sms.svg';
import GooglePlayIcon from '../../../../assets/img/sidebar/google-play-store.svg';

const SidebarSoftware = () => {
  const { t } = useContext(TranslationsContext);
  const { user } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary sx={{ color: '#96c', borderLeftColor: '#96c' }}>
        {t('widget:software:title')}
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {(Number(user.site) === 3 || !user.site) && (
            <>
              <ListItem disablePadding>
                <ListItemButton component="a" href="https://play.google.com/store/apps/details?id=ua.alphasms.app" target="_self">
                  <ListItemIcon><img src={GooglePlayIcon} alt="GooglePlay" width={36} /></ListItemIcon>
                  <ListItemText primary="Google Play" />
                </ListItemButton>
              </ListItem>
              { /* <ListItem disablePadding>
                <ListItemButton component="a" href="https://itunes.apple.com/ru/app/alphasms-servis-mobil-nogo/id898247591" target="_self">
                  <ListItemIcon><img src={SmsIcon} alt="sms" /></ListItemIcon>
                  <ListItemText primary={t('widget:software:ios')} />
                </ListItemButton>
              </ListItem> */ }
            </>
          )}
          <ListItem disablePadding>
            <ListItemButton component="a" href="/software/download/" target="_self">
              <ListItemIcon><img src={SmsIcon} alt="sms" /></ListItemIcon>
              <ListItemText primary={t('widget:software:windows')} />
            </ListItemButton>
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarSoftware;
