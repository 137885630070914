import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  CircularProgress,
  IconButton,
  Input,
  Toolbar,
  Typography,
} from '@mui/material';
import { Add, FileDownload } from '@mui/icons-material';

import TranslationsContext from '../../Container/Language/TranslationsContext';
import StateContext from '../../HO/State/StateContext';
import { FormConsumer, FormProvider } from '../../HO/Form';
import TableToolbarDelete from './TableToolbarDelete';
import TableToolbarExport from './TableToolbarExport';

const TableToolbar = (props) => {
  const { sendRequest } = useContext(StateContext);
  const { t } = useContext(TranslationsContext);
  const [loadingImport, setLoadingImport] = useState(false);

  const { children } = props;
  const { form } = props;
  const { getData } = props;
  const { selected } = props;
  const { multiDeleteRoute } = props;
  const { onCreate } = props;
  const { total } = props;
  const { exportData } = props;
  const { exportQueueRoute } = props;
  const { exportRoute } = props;
  const { importRoute } = props;
  const { setImportData } = props;

  const toolbarProps = {
    sx: {
      pl: {
        xs: 0,
        sm: 0,
      },
      pr: {
        xs: 1,
        sm: 1,
      },
    },
  };
  const addButtonProps = {
    onClick: onCreate,
    variant: 'contained',
    startIcon: <Add />,
    size: 'large',
    sx: {
      mr: 2,
    },
  };
  const typographyProps = {
    sx: {
      flex: '1 1 100%',
    },
    variant: 'h6',
    component: 'div',
  };
  const iconImportButtonProps = {
    component: 'span',
  };
  const exportProps = {
    exportData,
    children,
    exportQueueRoute,
    exportRoute,
    form,
    total,
  };

  if (selected.length > 0) {
    const formProps = {
      formName: multiDeleteRoute,
    };

    return (
      <Toolbar {...toolbarProps}>
        <Typography {...typographyProps}>
          {selected.length}
          {' '}
          {t('app:common:selected')}
        </Typography>
        <FormProvider {...formProps}>
          <FormConsumer>
            {(value) => (
              <TableToolbarDelete
                {...value || {}}
                getData={getData}
                body={{ ids: selected }}
              />
            )}
          </FormConsumer>
        </FormProvider>
      </Toolbar>
    );
  }
  const inputProps = {
    accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    type: 'file',
    onChange(event) {
      setLoadingImport(true);
      const body = new FormData();
      body.append('file', event.target.files[0]);

      sendRequest('fileToArray', {
        body,
        headers: {
          type: 'formData',
        },
      }).then((response) => {
        setLoadingImport(false);
        if (response) {
          setImportData(response.data);
        }
      });
    },
    style: {
      display: 'none',
    },
    disabled: loadingImport,
  };
  const labelProps = {
    title: t('app:common:import'),
  };

  return (
    <Toolbar {...toolbarProps}>
      {!!onCreate && (
        <Button {...addButtonProps}>{t('app:common:actions:add')}</Button>
      )}
      <Typography {...typographyProps}>{children || [t('app:common:total'), ` ${total || 0}`]}</Typography>
      <TableToolbarExport {...exportProps} />

      {!!importRoute && (
        <label {...labelProps} htmlFor={inputProps.id}>
          <Input {...inputProps} />
          <IconButton {...iconImportButtonProps}>
            {loadingImport
              ? <CircularProgress size={16} />
              : <FileDownload />}
          </IconButton>
        </label>
      )}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  // children: PropTypes.any,
  // form: PropTypes.instanceOf(Object),
  getData: PropTypes.func,
  selected: PropTypes.instanceOf(Array),
  multiDeleteRoute: PropTypes.string,
  exportData: PropTypes.instanceOf(Array),
  importRoute: PropTypes.string,
  onCreate: PropTypes.func,
  setImportData: PropTypes.func,
};

TableToolbar.defaultProps = {
  // children: '',
  getData() {},
  selected: [],
  multiDeleteRoute: '',
  exportData: null,
  importRoute: '',
  onCreate: null,
  setImportData: null,
};

export default TableToolbar;
