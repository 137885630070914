import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthConsumer } from '../../components/HO/Auth';

const LoggedRoute = ({ condition, valid, invalid }) => {
  const { pathname } = useLocation();
  const { search } = useLocation();

  return (
    <AuthConsumer>
      {({ user }) => {
        const element = (condition(user) ? valid : invalid);

        if (typeof element === 'string') {
          if (element.substring(0, 4) === 'http') {
            window.location.href = `${element}${search}`;
            return null;
          }

          return (<Navigate to={`${element}${search}`} state={{ from: pathname }} />);
        }

        return element;
      }}
    </AuthConsumer>
  );
};

LoggedRoute.propTypes = {
  valid: PropTypes.node.isRequired,
  invalid: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  condition: PropTypes.func,
};

LoggedRoute.defaultProps = {
  condition: (user) => user,
};

export default LoggedRoute;
