import React from 'react';
import moment from 'moment';

import {
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ArrowForwardIosSharp, Close } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { accordionSummaryClasses } from '@mui/material/AccordionSummary';
import { TranslationsConsumer } from '../../../../Container/Language';

const QueueItemSummary = ({ queue, dropQueue }) => {
  const theme = useTheme();
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  let width = onlySmallScreen ? '220px' : '350px';
  if (queue.finished) {
    width = onlySmallScreen ? '250px' : '450px';
  }

  return (
    <AccordionSummary
      expandIcon={(<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />)}
      sx={{
        flexDirection: 'row-reverse',
        [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
          {
            transform: 'rotate(90deg)',
          },
        [`& .${accordionSummaryClasses.content}`]: {
          marginLeft: theme.spacing(1),
        },
        height: '58px',
      }}
    >
      <Grid sx={{ justifyContent: 'space-between' }} spacing={0} container>
        <Grid item>
          <Typography
            sx={{
              py: 0.5,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width,
            }}
            title={queue.title}
          >
            {queue.title}
          </Typography>
        </Grid>
        <Grid item>
          {queue.finished ? (
            <IconButton size="small" onClick={() => dropQueue(queue.id)}>
              <Close />
            </IconButton>
          ) : (
            <TranslationsConsumer>
              {({ t }) => (
                <Typography sx={{ color: 'text.secondary', pl: 1, py: 0.5 }}>
                  {onlySmallScreen ? null : `${t('app:queue:start-at')} `}
                  {moment(queue.started).format('LT')}
                </Typography>
              )}
            </TranslationsConsumer>
          )}
        </Grid>
      </Grid>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
        }}
      >
        <LinearProgress
          color={queue.finished ? ((queue?.result?.error || (queue?.result?.errors || []).length) ? 'error' : 'success') : 'primary'}
          variant="determinate"
          value={queue.percent}
          sx={{ '& .MuiLinearProgress-root': { height: '2px' } }}
        />
      </Box>
    </AccordionSummary>
  );
};

export default QueueItemSummary;
