import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  Box,
  Button,
  Popover,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import AuthContext from '../../../HO/Auth/AuthContext';
import { Consumer } from '../LayoutConfigContext';
import TranslationsContext from '../../../Container/Language/TranslationsContext';
import configs from '../../../../configs';

const UserInfo = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const { form: { language }, t } = useContext(TranslationsContext);

  return (
    <>
      <Typography
        aria-owns={anchorEl ? 'mouse-over-userinfo' : undefined}
        aria-haspopup="true"
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        // onMouseLeave={(e) => setAnchorEl(null)}
        variant="body2"
        component="div"
        sx={{ flexGrow: 1, ml: 1 }}
        className="user-info"
      >
        <Box
          sx={{
            overflow: 'hidden',
            maxWidth: '180px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            verticalAlign: 'text-bottom',
            pr: 0.5,
          }}
          className="user-info-name"
          title={user.firstName || t('widget:user:greetings')}
        >
          {user.firstName || t('widget:user:greetings')}
          {', '}
          {t('widget:user:account')}
          {' '}
        </Box>

        <Link to={`${configs.prefix || ''}/finance/`} style={{ color: user.balance.amount < 0 ? theme.palette.error.main : theme.palette.primary.main }}>
          <b style={{ fontSize: '1.2em' }}>
            {parseInt(user.balance.amount, 10)}
            ,
            <small>{(Math.abs(user.balance.amount) % 1).toFixed(4).substring(2)}</small>
          </b>
          {' '}
          {user.currency.symbol}
        </Link>
      </Typography>

      {[54, 55, 57, 59, 61, 62, 63, 66].indexOf(Number(user.site)) === -1 && (
        <Consumer>
          {({ colors }) => (
            <Popover
              id="mouse-over-userinfo"
              PaperProps={{ onMouseLeave: () => setAnchorEl(null) }}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              disableRestoreFocus
            >
              <Box sx={{
                px: 3, py: 2, textAlign: 'center', bgcolor: colors.backgroundColor,
              }}
              >
                <Typography variant="body1">{t('widget:user:enough')}</Typography>
                <Typography variant="body1">
                  {user.balance.amount > 0 ? Math.floor(user.balance.amount / user.price.one) : 0}
                  {' SMS'}
                </Typography>
                {language === 'uk' && (
                  <Typography variant="body1">
                    {t('widget:user:ukraine')}
                  </Typography>
                )}
                {user.balance.credit > 0 && (
                  <Typography variant="body1">
                    {t('widget:user:credit')}
                    {': '}
                    {user.balance.credit}
                    {' '}
                    {user.currency.symbol}
                  </Typography>
                )}
                {Object.keys(user.balance.deposit || {}).length > 0 && (
                  <Typography variant="body2">
                    {t('widget:user:deposit')}
                    {_.map(user.balance.deposit, (amount, key) => (
                      <Fragment key={`${user.id}-deposit-${key}`}>
                        {` ${key}: ${amount} ${user.currency.symbol}`}
                      </Fragment>
                    ))}
                  </Typography>
                )}
                {window.location.hostname.substring(0, 8) === 'alphasms' && (
                  <Button sx={{ mt: 1 }} variant="text" component="a" href="/about/prices/" target="_self">
                    {t('widget:user:tariff')}
                  </Button>
                )}
                <br />
                <Button sx={{ mt: 1 }} variant="contained" component={Link} to={`${configs.prefix || ''}/finance/`}>
                  {t('widget:user:add-full')}
                </Button>
              </Box>
            </Popover>
          )}
        </Consumer>
      )}
    </>
  );
};

export default UserInfo;
