import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { CircularProgress, IconButton } from '@mui/material';
import { FileUpload } from '@mui/icons-material';

import TranslationsContext from '../../Container/Language/TranslationsContext';
import StateContext from '../../HO/State/StateContext';

const ExportRouteButton = (props) => {
  const { t } = useContext(TranslationsContext);
  const { sendRequest } = useContext(StateContext);

  const { exportRoute } = props;
  const { form } = props;
  const { fileName, title } = props;

  const [loading, setLoading] = useState(false);

  const iconButtonProps = {
    title: title || t('app:common:export'),
    onClick() {
      setLoading(true);

      sendRequest(exportRoute, {
        body: form,
      }).then((response) => {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(response);
        a.setAttribute('download', fileName);
        a.click();

        setLoading(false);
      });
    },
    disabled: loading,
  };

  return (
    <IconButton {...iconButtonProps}>
      {loading ? (<CircularProgress size={24} />) : (<FileUpload />)}
    </IconButton>
  );
};

ExportRouteButton.propTypes = {
  exportRoute: PropTypes.string.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
};

ExportRouteButton.defaultProps = {

};

export default ExportRouteButton;
