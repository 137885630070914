import configs from '../configs';

let host = configs.apiUrl;

if (host === 'https://dev.alphasms.ua') {
  host += '/backend';
}

const routes = {
  auth: `${host}/panelUser/userinfo/`,

  // data
  translations: `${host}/panelUtils/getPanelTranslation/`,
  configs: `${host}/panelUtils/getConfigs/`,
  permissions: `${host}/panelUser/getPermissions/`,
  menuTop: `${host}/panelUser/getMenuTop/`,
  menuBottom: `${host}/panelUser/getMenuBottom/`,
  logout: `${host}/panelUser/logout/`,
  login: `${host}/ajax/login/`,
  registration: `${host}/ajax/register/`,
  recoverInit: `${host}/ajax/recoverInit/`,
  recoverConfirm: `${host}/ajax/recoverConfirm/`,
  // TODO /panelUser/geNotification/

  alphanames: `${host}/panelAlphaname/list/`,
  balanceHistory: `${host}/panelFinance/balanceHistory/`,
  blackList: `${host}/panelBlackList/getAll/`,
  cities: `${host}/panelUtils/cities/`,
  invoices: `${host}/panelFinance/invoices/`,
  countries: `${host}/panelUtils/countries/`,
  cryptocurrencyWallets: `${host}/panelFinance/getCryptocurrencies/`,
  currencies: `${host}/panelFinance/getCurrencies/`,
  customerList: `${host}/panelAddressbook/getAll/`,
  customerListPhones: `${host}/panelAddressbook/getOne/`,
  incomingOpenNight: `${host}/panelIncoming/openNight/`,
  incomingPrices: `${host}/panelIncoming/prices/`,
  incomingReport: `${host}/panelIncoming/report/`,
  incomingReportExport: `${host}/panelIncoming/export/`,
  languages: `${host}/panelUtils/getLanguages/`,
  oblenergoReport: `${host}/panelOblenergo/report/`,
  offers: `${host}/panelUser/getOffer/`,
  paymentSystems: `${host}/panelFinance/getPaymentSystems/`,
  paymentSubscriptions: `${host}/panelFinance/getPaymentSubscriptions/`,
  paymentSubscriptionHistory: `${host}/panelFinance/getPaymentSubscriptionHistory/`,
  paymentUnsubscribe: `${host}/panelFinance/paymentUnsubscribe/`,
  posterOrder: `${host}/panelPoster/getOrder/`,
  phonebookCleaner: `${host}/panelTools/searchPhonebookCleaner/`,
  rcsAlphanames: `${host}/panelAlphaname/listRcs/`,
  recipientPhones: `${host}/panelUtils/autocompletePhone/`,
  regions: `${host}/panelUtils/regions/`,
  reportApi: `${host}/panelReport/api/`,
  reportBulk: `${host}/panelReport/bulk/`,
  reportBulkProgress: `${host}/panelReport/bulkProgress/`,
  reportDetail: `${host}/panelReport/bulkDetails/`,
  reportClean: `${host}/panelReport/clean/`,
  reportSingle: `${host}/panelReport/single/`,
  reportShortLink: `${host}/panelReport/shortLink/`,
  scarpelAlphaname: `${host}/panelScarpelAlphaname/getMessages/`,
  scarpelAlphanames: `${host}/panelScarpelAlphaname/getAlphanames/`,
  scarpelDirections: `${host}/panelScarpelAlphaname/getDirections/`,
  smsAlphanames: `${host}/panelAlphaname/listSms/`,
  smsByDay: `${host}/panelStats/days/`,
  smsByGate: `${host}/panelStats/gates/`,
  smsByIncome: `${host}/panelStats/income/`,
  smsByOperator: `${host}/panelStats/operators/`,
  smsByReferral: `${host}/panelStats/gclid/`,
  smsBySender: `${host}/panelStats/alphanames/`,
  smsBySite: `${host}/panelStats/sites/`,
  smsBySource: `${host}/panelStats/sources/`,
  smsByUser: `${host}/panelStats/customers/`,
  smsByViber: `${host}/panelStats/viber/`,
  smsScheduler: `${host}/panelSms/schedulerList/`,
  supportDownload: `${host}/panelSupport/download/`,
  supportOwnTickets: `${host}/panelSupport/getOwnTickets/`,
  supportTicket: `${host}/panelSupport/getTicket/`,
  supportTickets: `${host}/panelSupport/getTickets/`,
  supportUpload: `${host}/panelSupport/upload/`,
  user: `${host}/panelUser/userinfo/`,
  userApi: `${host}/panelUser/apiSettings/`,
  userApiKeys: `${host}/panelUser/apiKeys/`,
  userChangePassword: `${host}/panelUser/changePassword/`,
  userDetails: `${host}/panelUser/userinfodetails/`,
  userInvite: `${host}/panelUser/invite/`,
  userSecurity: `${host}/panelUser/security/`,
  viberAlphanames: `${host}/panelAlphaname/listviber/`,
  viberDialogs: `${host}/panelViberDialog/getAll/`,
  viberDialogsChanges: `${host}/panelViberDialog/getChanges/`,
  viberDialogMessages: `${host}/panelViberDialog/getMessages/`,
  viberTemplates: `${host}/panelTemplate/getViberTemplates/`,
  weather: `${host}/informer/weather/`,
  // forms
  altegio: `${host}/ajax/altegioAgree/`,
  alphanameCreate: `${host}/panelAlphaname/create/`,
  alphanameUpdate: `${host}/panelAlphaname/update/`,
  alphanamesDelete: `${host}/panelAlphaname/remove/`,
  arrayToFile: `${host}/panelUtils/arrayToFile/`,
  balancePromo: `${host}/panelFinance/promo/`,
  balanceTransfer: `${host}/panelFinance/transfer/`,
  blackListExport: `${host}/panelBlackList/export/`,
  blackListDelete: `${host}/panelBlackList/remove/`,
  blackListSave: `${host}/panelBlackList/save/`,
  bulkBlock: `${host}/panelSms/bulkBlock/`,
  bulkCalculate: `${host}/panelSms/bulkCalculate/`,
  bulkPause: `${host}/panelSms/bulkPause/`,
  bulkResume: `${host}/panelSms/bulkResume/`,
  bulkRemove: `${host}/panelSms/bulkRemove/`,
  bulkSend: `${host}/panelSms/bulkSend/`,
  bulkTest: `${host}/panelSms/bulkTest/`,
  bulkUnblock: `${host}/panelSms/bulkUnblock/`,
  countParts: `${host}/panelUtils/countParts/`,
  customerListCreate: `${host}/panelAddressbook/create/`,
  customerListDelete: `${host}/panelAddressbook/remove/`,
  customerListPhonesExport: `${host}/panelAddressbook/exportPhones/`,
  customerListPhonesDelete: `${host}/panelAddressbook/removePhone/`,
  customerListDeliveredSave: `${host}/panelAddressbook/saveDelivered/`,
  customerListPhonesUpload: `${host}/panelAddressbook/uploadPhones/`,
  customerListUpdate: `${host}/panelAddressbook/update/`,
  customerListUpload: `${host}/panelAddressbook/upload/`,
  fileToArray: `${host}/panelUtils/fileToArray/`,
  jobResult: `${host}/panelUtils/getJobResult/`,
  jobsResult: `${host}/panelUtils/getJobsResult/`,
  oblenergoSave: `${host}/panelOblenergo/send/`,
  posterOrderSave: `${host}/panelPoster/saveOrder/`,
  phonebookCleanerSave: `${host}/panelTools/createPhonebookCleaner/`,
  feedback: `${host}/panelUser/feedbackCreate/`,
  reportApiExport: `${host}/panelReport/apiExport/`,
  reportBulkExport: `${host}/panelReport/bulkExport/`,
  reportSingleExport: `${host}/panelReport/singleExport/`,
  reportDownload: `${host}/account/downloadReport/`,
  rcsSms: `${host}/panelSms/rcs/`,
  scarpelAlphanameCreate: `${host}/panelScarpelAlphaname/create/`,
  scarpelAlphanameCancel: `${host}/panelScarpelAlphaname/cancel/`,
  scarpelAlphanameUpdate: `${host}/panelScarpelAlphaname/update/`,
  scarpelAlphanameUpload: `${host}/panelScarpelAlphaname/upload/`,
  setLang: `${host}/panelUser/setlanguage/`,
  smsCalculate: `${host}/panelSms/singleCalculate/`,
  smsSend: `${host}/panelSms/singlesend/`,
  smsSchedulerActive: `${host}/panelSms/schedulerToggle/`,
  smsSchedulerCreate: `${host}/panelSms/schedulerSave/`,
  smsSchedulerDelete: `${host}/panelSms/schedulerRemove/`,
  smsUpload: `${host}/panelSms/uploadPub/`,
  smsUploadAudio: `${host}/panelSms/uploadAudio/`,
  supportTicketClose: `${host}/panelSupport/closeTicket/`,
  supportTicketCreate: `${host}/panelSupport/createTicket/`,
  supportTicketOpen: `${host}/panelSupport/openTicket/`,
  supportTicketReply: `${host}/panelSupport/replyTicket/`,
  templateCategories: `${host}/panelTemplate/categories/`,
  templates: `${host}/panelTemplate/list/`,
  templateSave: `${host}/panelTemplate/save/`,
  templatesDelete: `${host}/panelTemplate/remove/`,
  templatesOwn: `${host}/panelTemplate/listOwn/`,
  transliterate: `${host}/panelUtils/transliterate/`,
  toolsUpload: `${host}/panelTools/upload/`,
  userApiCreate: `${host}/panelUser/createApiKey/`,
  userApiUpdate: `${host}/panelUser/updateApiKey/`,
  userApiSave: `${host}/panelUser/apisettingssave/`,
  userApiKeysDelete: `${host}/panelUser/removeApiKeys/`,
  // userApyKeySave: `${host}/panelUser/apikeyGenerate/`,
  userContracts: `${host}/panelFinance/contracts/`,
  userContractFileDownload: `${host}/panelFinance/contractFileDownload/`,
  userContractFiles: `${host}/panelFinance/contractFiles/`,
  userContractFileSave: `${host}/panelFinance/contractFileUpload/`,
  userContractFileTypes: `${host}/panelFinance/contractFileTypes/`,
  userDestroy: `${host}/panelUser/userDestroy/`,
  userDetailsSave: `${host}/panelUser/userInfoDetailsSave/`,
  userVerifyEmail: `${host}/panelUser/verifyEmail/`,
  userVerifyEmailSend: `${host}/panelUser/sendVerifiedEmail/`,
  userInviteSave: `${host}/panelUser/inviteSave/`,
  userSecuritySave: `${host}/panelUser/securitySave/`,
  userSecuritiesDelete: `${host}/panelUser/removeSecurities/`,
  validateXml: `${host}/panelUtils/validatexml/`,
  viberImageUpload: `${host}/panelViberDialog/uploadPicture/`,
  viberTemplatesExport: `${host}/panelTemplate/viberTemplatesExport/`,
  viberTemplateSave: `${host}/panelTemplate/saveViberTemplate/`,
  viberTemplatesDelete: `${host}/panelTemplate/removeViberTemplates/`,
  viberMessageSend: `${host}/panelViberDialog/saveMessage/`,
  viberRequest: `${host}/panelSms/sendRequest/`,
  viberSms: `${host}/panelSms/viber/`,
  voipCalculate: `${host}/panelSms/voipCalculate/`,
  voipSend: `${host}/panelSms/voipSend/`,
  voipTest: `${host}/panelSms/voipTest/`,
  // payment
  airwallex: `${host}/panelFinance/airwallex/`,
  airwallexPay: `${host}/order/result/method/airwallex/`,
  cardpay: `${host}/panelFinance/cardpay/`,
  crypto: `${host}/panelFinance/cryptocurrency/`,
  easypay: `${host}/panelFinance/payeasypay/`,
  interkassa: `${host}/panelFinance/interkassa/`,
  liqpay: `${host}/panelFinance/liqpay/`,
  invoice: `${host}/panelFinance/invoice/`,
  paypal: `${host}/panelFinance/paypal/`,
  paypalPay: `${host}/order/result/method/paypal/`,
  portmone: `${host}/panelFinance/portmone/`,
  stripe: `${host}/panelFinance/stripe/`,
  stripePay: `${host}/order/result/method/stripe/`,
  walletone: `${host}/panelFinance/walletone/`,
  wayforpay: `${host}/panelFinance/wayforpay/`,

  // data
  managerAutoInvoices: `${host}/panelManagerFinance/getAutoInvoices/`,
  managerAlphanames: `${host}/panelManagerAlphaname/getAll/`,
  managerAlphanamesRequests: `${host}/panelManagerAlphaname/getRequests/`,
  managerAnalysis: `${host}/panelManagerAnalysis/analyze/`,
  managerCryptocurrencyWallets: `${host}/panelManagerCryptocurrencyWallet/getAll/`,
  managerEmailNotifications: `${host}/panelManagerNotificationEmail/getAll/`,
  managerFinanceGates: `${host}/panelFinance/getGatesBalance/`,
  managerTransactions: `${host}/panelFinance/getTransactions/`,
  managerGateCountries: `${host}/panelManagerRouter/searchCountries/`,
  managerGateways: `${host}/panelManagerGate/search/`,
  managerInvoices: `${host}/panelFinance/invoicesByManager/`,
  managerMenuBottom: `${host}/panelManagerMenu/getFooter/`,
  managerMenuTop: `${host}/panelManagerMenu/getHeader/`,
  managerNews: `${host}/panelManagerNews/getAll/`,
  managerNewsCategories: `${host}/panelManagerNews/getAllCategories/`,
  managerNewsTags: `${host}/panelManagerNews/getAllTags/`,
  managerSiteConfig: `${host}/panelManagerSite/getConfig/`,
  managerSiteManual: `${host}/panelManagerSite/getManual/`,
  managerSitePages: `${host}/panelManagerSite/getPages/`,
  managerSites: `${host}/panelPage/getSites/`,
  managerSoftwareAbout: `${host}/panelSoftware/getAbout/`,
  managerSoftwareVersion: `${host}/panelSoftware/get/`,
  managerSmsNotifications: `${host}/panelManagerNotificationSms/getAll/`,
  managerPages: `${host}/panelPage/getAll/`,
  managerPanelTranslations: `${host}/panelManagerTranslation/getAll/`,
  managerPayments: `${host}/panelFinance/getPayments/`,
  managerPaymentMethods: `${host}/panelFinance/getPaymentMethods/`,
  managerPaymentSystems: `${host}/panelManagerPaymentSystem/getAll/`,
  managerPhonesFail: `${host}/panelTools/fail/`,
  managerReviews: `${host}/panelUserManager/getReviews/`,
  managerRoutes: `${host}/panelManagerRouter/search/`,
  managerRouteGroups: `${host}/panelManagerRouter/searchGroups/`,
  managerRouterGateways: `${host}/panelManagerRouter/searchGate/`,
  managerUser: `${host}/panelUserManager/getOne/`,
  managerUserAccess: `${host}/panelUserManager/getAccessRights/`,
  managerUserApiLogs: `${host}/panelUserManager/getApiLogs/`,
  managerUserChangeHistory: `${host}/panelUserManager/changeHistory/`,
  managerUserCreditHistory: `${host}/panelFinance/creditHistory/`,
  managerUsers: `${host}/panelUserManager/getAll/`,
  managerUsersByInvoice: `${host}/panelUserManager/getbyinvoice/`,
  managerUserGates: `${host}/panelUserManager/getGates/`,
  managerUsernameRequests: `${host}/panelUserManager/getUsernameRequests/`,
  managerUserPaymentSystems: `${host}/panelManagerPaymentSystem/getUserAll/`,
  managerUserPriceHistory: `${host}/panelUserManager/getPricesLog/`,
  managerUserVisits: `${host}/panelUserManager/getVisits/`,
  managerViberAlphanames: `${host}/panelManagerAlphaname/getAll/`,
  managerViberGateways: `${host}/panelManagerTemplate/getGates/`,
  managerWhiteList: `${host}/panelManagerWhiteList/getAll/`,
  // forms
  managerSendAutoInvoice: `${host}/panelManagerFinance/sendAutoInvoice/`,
  managerAlphanameCreate: `${host}/panelManagerAlphaname/create/`,
  managerAlphanameUpdate: `${host}/panelManagerAlphaname/update/`,
  managerAlphanameUpdateFile: `${host}/panelManagerAlphaname/updateFromFile/`,
  managerAlphanameUpdateList: `${host}/panelManagerAlphaname/updateFromList/`,
  managerAlphanameUpload: `${host}/panelManagerAlphaname/upload/`,
  managerActivationCodeResend: `${host}/panelUserManager/resendActivationCode/`,
  managerCryptocurrencyWalletSave: `${host}/panelManagerCryptocurrencyWallet/save/`,
  managerCryptocurrencyWalletDelete: `${host}/panelManagerCryptocurrencyWallet/remove/`,
  managerClearPhones: `${host}/panelTools/clear/`,
  managerEmailNotificationSave: `${host}/panelManagerNotificationEmail/save/`,
  managerFinanceAdd: `${host}/panelFinance/add/`,
  managerFinanceUpload: `${host}/panelFinance/upload/`,
  managerFinanceImport: `${host}/panelFinance/import/`,
  managerFinanceConfirm: `${host}/panelFinance/confirm/`,
  managerImportPricesLog: `${host}/panelManagerPrice/getLog/`,
  managerImportPricesFile: `${host}/panelManagerPrice/importFile/`,
  managerImportPricesOptions: `${host}/panelManagerPrice/importOptions/`,
  managerImportPrices: `${host}/panelManagerPrice/importPrices/`,
  managerInvoiceCreate: `${host}/panelFinance/invoiceByManager/`,
  managerActivationAttemptsReset: `${host}/panelUserManager/resetActivationAttempts/`,
  managerLoginAttemptsReset: `${host}/panelUserManager/resetLoginAttempts/`,
  managerPhonesSearch: `${host}/panelTools/search/`,
  managerGatewayDelete: `${host}/panelManagerGate/remove/`,
  managerGatewayUpdate: `${host}/panelManagerGate/update/`,
  managerGatewayCreate: `${host}/panelManagerGate/create/`,
  managerMenuSave: `${host}/panelManagerMenu/save/`,
  managerMenuDelete: `${host}/panelManagerMenu/remove/`,
  managerPageSave: `${host}/panelPage/save/`,
  managerPagesDelete: `${host}/panelPage/remove/`,
  managerPanelTranslationSave: `${host}/panelManagerTranslation/save/`,
  managerPaymentSystemSave: `${host}/panelManagerPaymentSystem/save/`,
  managerRouterCreate: `${host}/panelManagerRouter/create/`,
  managerRouterCountry: `${host}/panelManagerRouter/searchCountry/`,
  managerRoutesDelete: `${host}/panelManagerRouter/remove/`,
  managerRouterOperator: `${host}/panelManagerRouter/searchOperator/`,
  managerRouterUpdate: `${host}/panelManagerRouter/update/`,
  managerRouterUser: `${host}/panelManagerRouter/searchUser/`,
  managerSoftwareAboutSave: `${host}/panelSoftware/saveAbout/`,
  managerSoftwareVersionDelete: `${host}/panelSoftware/remove/`,
  managerSoftwareVersionUpload: `${host}/panelSoftware/upload/`,
  managerSoftwareVersionSave: `${host}/panelSoftware/save/`,
  managerSitePageConfigSave: `${host}/panelManagerSite/saveConfig/`,
  managerSitePageManualSave: `${host}/panelManagerSite/saveManual/`,
  managerSitePagesDelete: `${host}/panelManagerSite/removePages/`,
  managerSitePageSave: `${host}/panelManagerSite/savePage/`,
  managerSiteUpload: `${host}/panelManagerSite/upload/`,
  managerSmsNotificationSave: `${host}/panelManagerNotificationSms/save/`,
  managerNewsCategorySave: `${host}/panelManagerNews/saveCategory/`,
  managerNewsCategoryDelete: `${host}/panelManagerNews/removeCategory/`,
  managerNewsTagSave: `${host}/panelManagerNews/saveTag/`,
  managerNewsTagDelete: `${host}/panelManagerNews/removeTag/`,
  managerNewsDelete: `${host}/panelManagerNews/remove/`,
  managerNewsUpload: `${host}/panelManagerNews/uploadPicture/`,
  managerNewsSave: `${host}/panelManagerNews/save/`,
  managerNewsletterSend: `${host}/panelManagerNewsletter/send/`,
  managerNewsletterSites: `${host}/panelManagerNewsletter/getSites/`,
  managerNewsletterUpload: `${host}/panelManagerNewsletter/upload/`,
  managerUserAccessSave: `${host}/panelUserManager/saveAccessRights/`,
  managerUserBalanceSave: `${host}/panelFinance/transfer/`,
  managerUserBalanceHistoryExport: `${host}/panelFinance/balanceHistoryExport/`,
  managerUserDepositSave: `${host}/panelFinance/deposit/`,
  managerUserContractDelete: `${host}/panelUserManager/removeContract/`,
  managerUserContractRequisitesSave: `${host}/panelUserManager/saveContractDetails/`,
  managerUserContractDetailSave: `${host}/panelUserManager/saveContractDetails/`,
  managerUserContractFileArchive: `${host}/panelManagerFinance/contractFileArchive/`,
  managerUserContractSave: `${host}/panelUserManager/saveContract/`,
  managerUserContractODB: `${host}/panelUserManager/openDataBot/`,
  managerUserCreditSave: `${host}/panelUserManager/setCredit/`,
  managerUserEmailAccountantSave: `${host}/panelUserManager/setEmailAccountant/`,
  managerUserEmailRatesSave: `${host}/panelUserManager/setEmailRates/`,
  managerUserEmailSave: `${host}/panelUserManager/setEmail/`,
  managerUserGateSave: `${host}/panelUserManager/saveGate/`,
  managerUsernameConfirm: `${host}/panelUserManager/confirmUsernameRequest/`,
  managerUsernameRequestSave: `${host}/panelUserManager/saveUsernameRequest/`,
  managerUserManager: `${host}/panelUserManager/saveManager/`,
  managerUserMinBalanceSave: `${host}/panelUserManager/saveMinBalance/`,
  managerUserNoticeSave: `${host}/panelUserManager/saveNotice/`,
  managerUserPaymentSystemSave: `${host}/panelManagerPaymentSystem/saveUser/`,
  managerUserPriceSave: `${host}/panelUserManager/savePrice/`,
  managerUserSenderSave: `${host}/panelUserManager/saveAlphanameRequest/`,
  managerUsersExport: `${host}/panelUserManager/export/`,
  managerUserSubscribe: `${host}/panelUserManager/saveSubscribed/`,
  managerUserStateSave: `${host}/panelUserManager/saveState/`,
  managerUserTypeSave: `${host}/panelUserManager/saveType/`,
  managerViberTemplatesSave: `${host}/panelManagerTemplate/saveViberTemplates/`,
  managerWhiteListDelete: `${host}/panelManagerWhiteList/remove/`,
  managerWhiteListSave: `${host}/panelManagerWhiteList/save/`,
};

export default routes;
