import React, { Fragment, useContext } from 'react';
import { v4 } from 'uuid';
import _ from 'lodash';

import { Typography } from '@mui/material';

import AuthContext from '../../../../../components/HO/Auth/AuthContext';
import SmsResultErrors from './SmsResultErrors';
import SmsResultText from './SmsResultText';
import Table from '../../../../../components/Smart/Table/Table';
import TranslationsContext from '../../../../../components/Container/Language/TranslationsContext';

const SmsResult = ({ result, text, percent }) => {
  const { t } = useContext(TranslationsContext);
  const { user } = useContext(AuthContext);
  const { errors } = result || {};

  if ((errors || []).length && errors[0] !== 'ERROR_BALANCE') {
    return (<SmsResultErrors errors={errors} />);
  }

  if (result) {
    const {
      data,
      command,
      duration,
      alphaname,
      total_price: totalPrice,
    } = result;

    const tableProps = {
      disableToolbar: true,
      total: data ? data.length : 0,
      head: [{
        id: 'form-table-operator',
        name: 'operator',
        label: t('app:controller:sms:popup:table:operator'),
        colSpan: 2,
      }, {
        id: 'form-table-qty',
        name: 'sms_parts',
        label: t('app:controller:sms:popup:table:qty'),
      }, {
        id: 'form-table-price',
        name: 'part_price',
        label: [t('app:controller:sms:popup:table:price'), user.currency ? `, ${user.currency.symbol}` : null],
      }, {
        id: 'form-table-price-total',
        name: 'price-total',
        label: [t('app:controller:sms:popup:table:price-total'), user.currency ? `, ${user.currency.symbol}` : null],
      }],
      rows: [],
      keys: ['country', 'operator', 'sms_parts', 'part_price', 'price'],
    };
    if (data instanceof Array) {
      tableProps.rows = [...data];
      if (data.short_link_amount > 0) {
        tableProps.rows.push({
          country: t('app:controller:sms:popup:table:short_link'),
          operator: '',
          sms_parts: data.short_link_count,
          part_price: data.short_link_price,
          price: data.short_link_amount,
        });
      }
      tableProps.rows.push({
        country: [
          t('app:controller:sms:popup:table:total'),
          { colSpan: 4 },
        ],
        operator: totalPrice,
      });
    }
    const typographyProps = {
      color: 'success.main',
      sx: {
        mb: 2,
      },
      align: 'center',
      dangerouslySetInnerHTML: {
        __html: t('app:controller:sms:popup:send:report'),
      },
    };

    return (
      <>
        { /* ERROR_BALANCE */ }
        {(errors || []).length ? (
          <Typography color="error">
            {t('app:controller:sms:popup:condition')}
            {': '}
            {t('app:controller:sms:popup:condition:insufficient')}
            {totalPrice ? (
              <>
                {' '}
                {Math.round((user.balance.amount > 0
                  ? ((totalPrice - parseFloat(user.balance.credit))
                    - parseFloat(user.balance.amount))
                  : ((totalPrice - parseFloat(user.balance.credit))
                    + parseFloat(user.balance.amount))) * 100) / 100}
                {user.currency.symbol}
              </>
            ) : null}
          </Typography>
        ) : null}

        {data && data.id && (errors || []).length === 0 ? (
          <Typography dangerouslySetInnerHTML={{ __html: t('app:controller:sms:popup:send:report') }} />
        ) : null}
        {data && data.amount ? (
          <Typography>
            {t('app:controller:sms:popup:price')}
            {': '}
            {data.amount}
            {user.currency.symbol}
          </Typography>
        ) : null}
        {user.balance && user.balance.amount > 0 && command === 'api-send' && (errors || []).length === 0 ? (
          <Typography>{t('app:controller:sms:popup:send:complete')}</Typography>
        ) : null}
        {result.total_phones ? (
          <Typography>
            {t('app:controller:sms:popup:recipients')}
            {': '}
            {result.total_phones}
          </Typography>
        ) : null}

        {duration && duration > 0 ? (
          <Typography>
            {t('app:controller:sms:popup:duration')}
            {': '}
            {duration}
          </Typography>
        ) : (result.total_parts ? (
          <Typography>
            {t('app:controller:sms:popup:parts')}
            {': '}
            {result.total_parts}
          </Typography>
        ) : null)}

        {alphaname ? (
          <Typography sx={{ mb: 2 }}>
            {t('app:controller:sms:form:alphaname:label')}
            {': '}
            {alphaname.name}
            {', '}
            {alphaname.status && t(`app:alphaname:state-${alphaname.status}`)}
          </Typography>
        ) : null}

        {command === 'api-send' && (errors || []).length === 0 && (<Typography {...typographyProps} />)}

        {data instanceof Array ? (
          <Table {...tableProps} />
        ) : _.map(data, (dataItem, key) => {
          if (typeof dataItem !== 'object' || !dataItem.qty || typeof dataItem.stats !== 'object') {
            return null;
          }

          const tProps = {
            disableToolbar: true,
            total: dataItem.stats.length,
            head: tableProps.head,
            rows: [...dataItem.stats],
            keys: ['country', 'operator', 'qty', 'price', 'amount'],
          };

          if (dataItem.short_link_amount > 0) {
            tProps.rows.push({
              country: t('app:controller:sms:popup:table:short_link'),
              operator: '',
              qty: dataItem.short_link_count,
              price: dataItem.short_link_price,
              amount: dataItem.short_link_amount,
            });
          }

          if (dataItem.amount) {
            tProps.rows.push({
              country: [
                t('app:controller:sms:popup:table:total'),
                { colSpan: 4 },
              ],
              operator: dataItem.amount,
            });
          }

          return (
            <Fragment key={v4()}>
              <Typography sx={{ mt: 2 }}>{key}</Typography>
              {dataItem.errors && dataItem.errors.length && user.balance ? (
                <Typography>
                  {t('app:controller:sms:popup:condition')}
                  {': '}
                  {t('app:controller:sms:popup:condition:insufficient')}
                  {user.balance.amount > 0
                    ? (dataItem.amount - user.balance.credit - user.balance.amount)
                    : (dataItem.amount - user.balance.credit + user.balance.amount)}
                  {user.currency.symbol}
                </Typography>
              ) : null}
              <Typography>
                {t('app:controller:sms:popup:recipients')}
                {': '}
                {dataItem.qty}
              </Typography>
              <Table {...tProps} />
            </Fragment>
          );
        })}
      </>
    );
  }

  return (
    <>
      <Typography align="center" sx={{ mt: 2 }}>{t('app:controller:sms:popup:wait')}</Typography>
      <Typography align="center" sx={{ mb: 2 }}>
        <SmsResultText text={text} />
        {' - '}
        {percent || 0}
        %
      </Typography>
    </>
  );
};

export default SmsResult;
